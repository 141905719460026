<template>  
  <div>
    <div class="background_basic">      
      <div style="height:1px"></div>
      <v-card class="ma-4">  
        <v-toolbar dense flat color="white">
            <v-toolbar-title class="grey--text text--darken-3">
              <v-icon class="mr-2">mdi-information</v-icon>이벤트 이력관리
            </v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-btn color="blue" text @click="initialize()" class="font-weight-bold text-subtitle-1">
              <v-icon big class="mr-2">refresh</v-icon>초기화
            </v-btn>         
            <v-btn color="blue" text @click="ExcelExpert('이벤트 이력관리')" class="ml-2 font-weight-bold text-subtitle-1">
              <v-icon big class="mr-2">mdi-microsoft-excel</v-icon>엑셀
            </v-btn>             
            <v-spacer></v-spacer>           
        </v-toolbar>        
        <v-divider></v-divider> 

        
        <v-card flat class="d-flex ml-3 mr-3">                
          <v-card flat class="ml-0 mb-n4" height="52">             
            <IronDate ref="IronDateRef" v-model="sNowSelectDate" @update="onDateRangeChange"/>
          </v-card>                                 
          
          <v-card class="ml-3 mb-n4" flat style="max-width:150px;"  height="52">
            <v-text-field class="ml-2 mb-1" readonly label="위치" v-model="cNowLocationNm" append-icon="mdi-close" @click:append="onNowLocationDel" @click="selectMap" placeholder="위치를 선택하세요."  style="max-width:250px;"></v-text-field>
          </v-card>
          
          <v-card class="ml-3 mb-n4" flat style="max-width:250px;"  height="52">
            <v-select v-model="cNowEvent" class="mb-n5" label="이벤트" :items="zDeviceEventAll" item-value="sId" item-text="sNm"></v-select> 
          </v-card>

          <v-card class="ml-3 mb-n4" flat style="max-width:250px;"  height="52">
            <v-select v-model="cResult" class="mb-n5" label="조치결과" :items="zEventRetType" item-value="sId" item-text="sNm"></v-select> 
          </v-card>
          
          <v-text-field
            v-model="cNowSearchKeyWord"
            class="ml-3 mb-n4"   
            label="검색어"
            placeholder="맥주소 또는 이름 입력"
            persistent-placeholder
            style="max-width:250px;"
            Regular
            v-on:keyup.enter="Search()"
          ></v-text-field>
          
          <v-btn icon class="ml-n8 mt-3 text-subtitle-1" @click="Search()">
            <v-icon>search</v-icon>
          </v-btn>         
        </v-card>       
      </v-card>
      
      <v-data-table 
        class="ma-4 elevation-3"
        :items-per-page="15"
        fixed-header
        height="calc(100vh - 227px)" 
        :headers="headers" 
        :items="Records"
      >
      <template v-slot:item.cUserNm="{ item }">
        <div v-if="item.cCorpNm != 'NONE' " small label color="grey lighten-3">            
          {{item.cCorpNm + " / " + item.cUserNm}}
        </div>          
        <div v-else-if="item.cUserNm != 'NONE' " small label color="grey lighten-3">{{item.cUserNm}}</div>          
      </template> 
      <template v-slot:item.cTagId="{ item }">
        <div v-if="item.cTagId != 'NONE' " small label color="grey lighten-3">{{item.cTagId}}</div>          
      </template> 
      <template v-slot:item.cAlarmNm="{ item }">
        <v-chip  small label :color="getColor(item.cType)" dark>{{item.cType}}:{{item.cAlarmNm}}</v-chip>          
      </template> 
      <template v-slot:item.cLocation="{ item }">
        <v-btn icon color="grey darken-2" dark @click="showPos(item)"><v-icon>mdi-map</v-icon></v-btn>
      </template>

      <template v-slot:item.cChecked="{ item }">
        <v-chip small label v-if="item.cChecked == 'Y'" color="grey lighten-2">조치</v-chip>
        <v-chip small label v-if="item.cChecked == 'N'" dark color="red lighten-2">미조치</v-chip>
      </template>
      <template v-slot:item.cResultChip="{ item }">          
        <div v-if="item.cChecked == 'Y'">
          <v-chip v-if="item.cCheckType=='99'" label small color="grey darken-2" dark>{{item.cCheckData}} / {{item.cUpdateDateTime}}</v-chip>
          <v-chip v-else-if="item.cCheckType=='00'" label small color="grey lighten-2" >단순실수 / {{item.cUpdateDateTime}} </v-chip>
          <v-chip v-else-if="item.cCheckType=='01'" label small color="red darken-2" dark>출입승인 위반(무단 출입) / {{item.cUpdateDateTime}} </v-chip>
          <v-chip v-else-if="item.cCheckType=='02'" label small color="green darken-2" dark>신호 감지 오류 / {{item.cUpdateDateTime}} </v-chip>
          <v-chip v-else-if="item.cCheckType=='03'" label small color="green darken-2" dark>Tag 관련 오류 / {{item.cUpdateDateTime}} </v-chip>
          <v-chip v-else-if="item.cCheckType=='04'" label small color="green darken-2" dark>기타 시스템 오류 / {{item.cUpdateDateTime}} </v-chip>          
        </div>
        <div v-else>
          <v-btn small :color="getColor2(item.cChecked)" dark @click="SetEventCheckFunc(item)" :disabled="!ProgramPermissionInfo.okU">결과등록</v-btn>
        </div>          
      </template>        
      <template v-slot:item.cUserNmCheck="{ item }">
        <div v-if="item.cUserNmCheck != 'NONE' " small label color="grey lighten-3">            
          {{item.cUserNmCheck}}
        </div>                  
      </template> 
    </v-data-table>     
    
    <GetSelMapOne ref="GetSelMapOneRef" @onGetMap="onGetMap" />   
    <MapViewPOS ref="MapViewPOSRef"/> 
    <SetEventCheck ref="SetEventCheckRef" @onSetEventCheck="onSetEventCheck" />  
    </div>
  </div>
</template>

<script>
import axios from "axios"
import BasicInfo from "@/BasicInfo.js"
import XLSX from 'xlsx'
import Util from "../Util.js"
import { mapState } from "vuex";
import EventBus from '@/eventBus.js'
import IronDate from '../components/iron28Calendar/src/IronDate.vue'
import GetSelMapOne from "@/components/GetSelMapOne.vue";
import MapViewPOS from "@/components/MapViewPOS.vue";
import SetEventCheck from "@/components/SetEventCheck.vue";

//이벤트 이력관리 사이트
export default {
  components: {
    IronDate,
    GetSelMapOne,
    MapViewPOS,
    SetEventCheck,
  },

  computed: {
    ...mapState(["zDeviceEventAll", "ProgramPermissionInfo",]),
  },

  data: () => ({
    sNowSelectDate :  Util.sFormatDate(Date()) + " ~ " +  Util.sFormatDate(Date()),
    sStartDate : Util.sFormatDate(Date()),
    sEndDate : Util.sFormatDate(Date()),
    cNowSearchKeyWord : "",
    cMapGroupId : "00",
    cBuildingId : "00",
    cMapId : "00",
    cNowLocationNm: "ALL",         
    cNowEvent: "ALL",
    cResult: "ALL",
    // event: ["배터리잔량 기준이하", "배터리 교체필요", "장비 통신상태 불량", "비인가구역 진입"],
    zEventRetType : [
      {sId : "ALL", sNm : "모두"},
      {sId : "00", sNm : "단순실수"},
      {sId : "01", sNm : "출입승인 위반(무단 출입)"},
      {sId : "02", sNm : "신호 감지 오류"},
      {sId : "03", sNm : "Tag 관련 오류"},
      {sId : "04", sNm : "기타 시스템 오류"},        
      {sId : "99", sNm : "조치결과 직접입력"},
    ],
    headersExcel: [
      { class: "font-weight-bold subtitle-3", align: "center", text: '이벤트번호', value: 'cIdx', width: 140},
      { class: "font-weight-bold subtitle-2", align: "center", text: '맥주소(Tag)', value: 'cTagId'},
      { class: "font-weight-bold subtitle-2", align: "center", text: '구역정보', value: 'cMapNm', width: 140},
      { class: "font-weight-bold subtitle-2", align: "center", text: '이벤트', value: 'cAlarmNm'},      
      { class: "font-weight-bold subtitle-2", align: "center", text: '작업자', value: 'cUserNm', width: 160},
      { class: "font-weight-bold subtitle-2", align: "center", text: '업체명', value: 'cCorpNm', width: 160},
      { class: "font-weight-bold subtitle-2", align: "center", text: '연락처', value: 'cTel', width: 140},
      { class: "font-weight-bold subtitle-2", align: "center", text: '내용', value: 'cData', width: 250},
      { class: "font-weight-bold subtitle-2", align: "center", text: '발생일자', value: 'cCreateDateTime'},
      { class: "font-weight-bold subtitle-2", align: "center", sortable: false, text: '조치여부', value: 'cChecked'},
      { class: "font-weight-bold subtitle-2", align: "center", sortable: false, text: '조치결과', value: 'cResultChip'},            
      { class: "font-weight-bold subtitle-2", align: "center", sortable: false, text: '조치자', value: 'cUserNmCheck'},            
    ],
    headers: [
      { class: "font-weight-bold subtitle-3", align: "center", text: '이벤트번호', value: 'cIdx', width: 50},
      { class: "font-weight-bold subtitle-2", align: "center", text: '맥주소(Tag)', value: 'cTagId'},
      { class: "font-weight-bold subtitle-2", align: "center", text: '구역정보', value: 'cMapNm', width: 140},
      { class: "font-weight-bold subtitle-2", align: "center", text: '이벤트', value: 'cAlarmNm'},
      { class: "font-weight-bold subtitle-2", align: "center", text: '작업자', value: 'cUserNm', width: 160},
      { class: "font-weight-bold subtitle-2", align: "center", text: '연락처', value: 'cTel', width: 140},
      { class: "font-weight-bold subtitle-2", align: "center", text: '내용', value: 'cData', width: 250},
      { class: "font-weight-bold subtitle-2", align: "center", text: '발생일자', value: 'cCreateDateTime', width: 140},
      { class: "font-weight-bold subtitle-2", align: "center", sortable: false, text: '조치여부', value: 'cChecked'},
      { class: "font-weight-bold subtitle-2", align: "center", sortable: false, text: '조치결과', value: 'cResultChip'},      
      { class: "font-weight-bold subtitle-2", align: "center", sortable: false, text: '조치자', value: 'cUserNmCheck'},            
      { class: "font-weight-bold subtitle-2", align: "center", sortable: false, text: '위치보기', value: 'cLocation'},
    ],
    Records: [],    
  }),
   
  mounted () {
    this.initialize()
  },

  methods: {
    initialize() {
      this.cNowSearchKeyWord = "";
      this.cMapGroupId = "00";
      this.cBuildingId = "00";      
      this.cMapId = "00";

      this.cNowLocationNm = "ALL";  
      this.cNowEvent= "ALL";

      this.sNowSelectDate = Util.sFormatDate(Date()) + " ~ " +  Util.sFormatDate(Date());   
      this.$refs.IronDateRef.SetDate(this.sNowSelectDate);
      this.sStartDate = Util.sFormatDate(Date());
      this.sEndDate = Util.sFormatDate(Date());
      
      console.log("this.$route.params.cEvent", this.$route.params.cEvent); 
      if (this.$route.params.cEvent != undefined) {        
        this.cNowEvent = this.$route.params.cEvent;
      }
      this.Search();
    },

    getColor2(a) {
      if (a == "Y") return "#555555";
      else return "#000000";
    },
    showPos(item){
      if (BasicInfo.Is3D_MapMode) {
        EventBus.$emit("onMapPos", item.cTagId)
      } else {
        this.$refs.MapViewPOSRef.Show("발생위치", item, item.cPath, item.x, item.y, item.cData, item.cMapFullNm, item.cUserNm);
      }
    },

    getColor (type) {
      if (type == "EV0101") {
        return "red darken-3 ";  
      } else if (type == "EV02") {
        return "red lighten-3 ";  
      } else if (type == "EV0102") {
        return "orange darken-3 ";  
      } else if (type == "EV04") {
        return "grey darken-3 ";  
      } else if (type == "EV05") {
        return "green darken-3 ";  
      } else if (type == "EV06") {
        return "green lighten-1 ";  
      }
      return "grey lighten-3 ";
    },

    selectMap () {
      this.$refs.GetSelMapOneRef.show(); 
    },

    onGetMap(select) {
      this.cMapGroupId = select.cMapGroupId;  
      this.cBuildingId = select.cBuildingId;  
      this.cMapId = select.cMapId;  
      this.cNowLocationNm = select.cMapNm;  
    },

    onNowLocationDel () {
      this.cMapGroupId = "00";
      this.cBuildingId = "00";      
      this.cMapId = "00";
      this.cNowLocationNm = "ALL";  
    },
    
    onDateRangeChange(daterange){            
      this.sStartDate = Util.sFormatDate(daterange.startDate);
      this.sEndDate = Util.sFormatDate(daterange.endDate);      
      this.sNowSelectDate = this.sStartDate + " ~ " +  this.sEndDate;           
    },

    
    // 구역출입 목록을 조회함. (검색어를 같이 보냄)
    Search() {      
      this.Records = [];

      var posData = {
        cStartDatetime: this.sStartDate + " 00:00:00",  
        cEndDatetime  : this.sEndDate + " 23:59:59",        
        cMapGroupId : this.cMapGroupId,
        cBuildingId : this.cBuildingId,
        cMapId : this.cMapId,      
        cNowEvent: this.cNowEvent,
        cNowSearchKeyWord : this.cNowSearchKeyWord,
        cResult : this.cResult,
      };
      console.log(posData);
      axios
        .post(BasicInfo.UIL_API + "GetEventLog", posData, {
          headers: {
            Authorization: this.$store.state.UserInfo.Token
          }
        })
        .then(res => {
          if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }
          
          if (res.data.ResultCd === "00") { 
            this.Records = res.data.Result;
            for (var i = 0; i < this.Records.length; i++) {
              this.Records[i].cResultChip = "";
              if (this.Records[i].cChecked == 'Y') {
                if (this.Records[i].cCheckType=='99') {
                  this.Records[i].cResultChip = this.Records[i].cCheckData +  "/" + this.Records[i].cUpdateDateTime;
                } else if (this.Records[i].cCheckType=='00') {
                  this.Records[i].cResultChip = "단순실수/" + this.Records[i].cUpdateDateTime;
                } else if (this.Records[i].cCheckType=='01') {
                  this.Records[i].cResultChip = "출입승인 위반(무단 출입)/" + this.Records[i].cUpdateDateTime;
                } else if (this.Records[i].cCheckType=='02') {
                  this.Records[i].cResultChip = "신호 감지 오류/" + this.Records[i].cUpdateDateTime;
                } else if (this.Records[i].cCheckType=='03') {
                  this.Records[i].cResultChip = "Tag 관련 오류/" + this.Records[i].cUpdateDateTime;
                } else if (this.Records[i].cCheckType=='04') {
                  this.Records[i].cResultChip = "기타 시스템 오류/" + this.Records[i].cUpdateDateTime;
                }
              }
            }
            // console.log(res.data.Result); 
          } else {
            EventBus.$emit("onShowMsg",true,  "(" + res.data.ResultCd + ") " + res.data.Msg);
            console.log("(" + res.data.ResultCd + ") " + res.data.Msg);
          }
        })
        .catch(err => {
          EventBus.$emit("onShowMsg",true, ""+err);         
          console.log(err); 
        });
    },


    SetEventCheckFunc(data){      
      this.$refs.SetEventCheckRef.Show(data.cIdx);
    },

    onSetEventCheck(cIdx, cType, cData, cUpdateDateTime){      
      for (let i = 0; i < this.Records.length; i++) {
        if (this.Records[i].cIdx == cIdx) {
          this.Records[i].cChecked = "Y";                    
          this.Records[i].cCheckType = cType;
          this.Records[i].cCheckData = cData;
          this.Records[i].cUpdateDateTime = cUpdateDateTime;
          break;
        }
      }
    },

    ExcelExpert(name){
      var r = Util.GetExcel(this.Records, this.headersExcel);
      var animalWS = XLSX.utils.json_to_sheet(r)
      var wb = XLSX.utils.book_new() // make Workbook of Excel
      XLSX.utils.book_append_sheet(wb, animalWS, name) // sheetAName is name of Worksheet      
      XLSX.writeFile(wb, name + "-" + Util.sNowDate() + '.xlsx'); // name of the file is 'book.xlsx'
    },
  },
  
}

</script>


