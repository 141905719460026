<template>    
  <v-dialog max-width="500px" v-model="dialog">
    <v-card >              
      <v-toolbar dense flat color="white">
        <v-toolbar-title class="grey--text text--darken-3">
          조치
        </v-toolbar-title>          
        <v-divider class="mx-4" inset vertical></v-divider>                    
      </v-toolbar>
      <v-divider></v-divider>              
      <v-card-text>
        <v-select
          :items="zEventRetType"
          label="유형"
          item-text="name"
          item-value="value"
          return-object
          v-model="cType"
        ></v-select>

        <div v-show="cType.value == cInputTextCd">
          <v-textarea              
            v-model="cCheckData"
            label="조치결과 입력"            
            hint="조치결과를 입력하세요."
          ></v-textarea>
        </div>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer> 
        <v-btn color="black darken-1" text @click="closePage()"><v-icon class="mr-2">mdi-close</v-icon>닫기</v-btn>
        <v-btn color="blue darken-1" text @click="SetData()"><v-icon class="mr-2">check_circle</v-icon>저장</v-btn>        
      </v-card-actions>
    </v-card>    
  </v-dialog>  
</template>

<script>
import axios from "axios";
import BasicInfo from "@/BasicInfo.js";
import EventBus from '@/eventBus.js';


export default {
  components: {    
  },
  data() {
    return {
      dialog: false,
      sDate : "",      
      model : null,      
      cInputTextCd : "99",
      cType : {value : "00", name : "단순실수"},
      zType : [
        {value : "00", name : "단순실수"},
        {value : "01", name : "응급상황"},
        {value : "99", name : "조치결과 직접입력"},
      ],      
      zEventRetType : [
        {value : "00", name : "단순실수"},
        {value : "01", name : "출입승인 위반(무단 출입)"},
        {value : "02", name : "신호 감지 오류"},
        {value : "03", name : "Tag 관련 오류"},
        {value : "04", name : "기타 시스템 오류"},        
        {value : "99", name : "조치결과 직접입력"},
      ],
      cSOSIdx : "",
      cCheckData : "",
      headers: [        
        // { class: "font-weight-bold subtitle-2",  text: "아이디", value: "cApId", width: 120 },
        { class: "font-weight-bold subtitle-2",  text: "선택", value: "cSel", width: 80, sortable: false },     
        { class: "font-weight-bold subtitle-2",  text: "MAC", value: "cMac", width: 150 },      
        { class: "font-weight-bold subtitle-2",  text: "장비타입", value: "cType", width: 100 },
        { class: "font-weight-bold subtitle-2",  text: "전원타입Id", value: "cPowerId", width: 100 },
        { class: "font-weight-bold subtitle-2",  text: "배정", value: "cTreeNm", width: 100 },
      ],
      Records: [],
    };
  },  
  created() {
    this.dialog = false;
  },
  watch: {
    dialog(val) {
      val || this.closePage();
    }
  },
  mounted () {    
  },
  methods: {    
    closePage(){                
      this.dialog = false;
    },

    SetData() {      
      var posData = {
        cUserId: this.$store.state.UserInfo.UserId,
        cIdx : String(this.cIdx),
        cChecked : "Y",        
        cCheckType : this.cType.value,        
        cCheckData : this.cCheckData,
      };

      // console.log(posData);
      axios
        .post(BasicInfo.UIL_API + "SetEventCheck", posData, {
          headers: {
            Authorization: this.$store.state.UserInfo.Token
          }
        })
        .then(res => {
          if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }
          
          if (res.data.ResultCd === "00") {
            this.$emit("onSetEventCheck", this.cIdx, posData.cCheckType, posData.cCheckData, res.data.Result[0].cUpdateDateTime);
            this.dialog = false;  
          } else {
            EventBus.$emit("onShowMsg",true,  "(" + res.data.ResultCd + ") " + res.data.Msg);
          }
        })
        .catch(err => {
          EventBus.$emit("onShowMsg",true, ""+err);          
        });
    },

    Show: function(cIdx) {
      this.cIdx = cIdx;
      this.cType =  {value : "00", name : "단순실수"};
      this.cCheckData = "";
      this.dialog = true;      
    },
    
  }
};
</script>
